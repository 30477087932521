<template>
  <div class="bgm" >
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 标题 -->
        <title-enzh enTitle="CUSTOM DEVELOPMENT" zhTitle="定制开发" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
        <div class="main x_between">
            <div style="flex:1.3">
                <img src="@/assets/image/customDev/main_img.png" class="main_img">
            </div>
            <div style="flex:3">
                <div v-for="(item,index) in rows" :key="index" class="row x_between_y_center">
                    <div style="flex:2" class="y_center">
                        <img :src="item.icon" class="row_icon">
                        <div class="title" v-text="item.title"></div>
                    </div>
                    <div style="flex:4.7" class="describe" v-text="item.describe"></div>
                </div>
                <div class="bottom_box x_between_y_center">
                    <div v-for="(item,index) in bootomNav" :key="index" v-text="item"></div>
                </div>
            </div>
        </div>
    </div> 
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            rows:[
                {
                    icon:require("../assets/image/customDev/icon_group/icon0.svg"),
                    title:"ARCHICAD 源生功能开发",
                    describe:"团队专注于Archicad源生插件功能开发，公司开发团队拥有13年Archicad开发经验（含GDL参数化构件开发），对Archicad系统功能了如指掌，能为您定制最合适的解决方案和最优秀的产品。"
                },
                {
                    icon:require("../assets/image/customDev/icon_group/icon1.svg"),
                    title:"ARCHICAD 参数化构件库",
                    describe:"团队拥有13年GDL参数化构件开发经验，可以快速开发各种类型的参数化构件，从简单到复杂，满足您对构件参数化的所有需求。"
                },
                {
                    icon:require("../assets/image/customDev/icon_group/icon2.svg"),
                    title:"平台系统开发",
                    describe:"整合BIM设计数据，打通模型与数据库平台的沟通断层，更规范地管理企业数据，更高效的设计-采购-施工-运维一体化流程定制，降本增效，节约企业资源。"
                },
                {
                    icon:require("../assets/image/customDev/icon_group/icon3.svg"),
                    title:"APP混合开发",
                    describe:"整合BIMx二次开发，打通与平台数据库的连接，同时提供其他功能性APP开发配合设计管理流程，以专业的技术、成熟的行业经验为企业提供高效可靠的移动化服务，敏捷开发，快速上线，快速部署，抢占市场先机！"
                },
            ],
            bootomNav:["需求分析","方案定制","产品设计","高保真UI","功能开发","全面测试","交付使用","后期维护"]
        }
    },
    methods:{
        backHome(){
            this.$router.push("/")
        }
    },
    components:{
        topBanner,
        titleEnzh
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/customDev/custom_dev_-bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px!important;
  }
}
.main{
    margin-top: 40px;
    .main_img{
        width: 110%;
        height: 88vh;
        margin-left: -100px;
        margin-top: -80px;
    }
}

.row{
    margin-bottom: 60px;
    padding-left: 30px;
    font-family: '幼圆', sans-serif;
    font-size: 28px;
    .row_icon{
        width:56px;
        margin-right: 20px;
    }
    .title{
        font-size: 22px;
        font-family: "Agency FB Normal", "Agency FB", sans-serif;
        color:#fff;
    }
    .describe{
        font-size: 20px;
        color:#bbbbbb;
    }
}
.bottom_box{
    position: absolute;
    bottom: 5%;
    right: 4%;
    font-family: 'Arial Normal', 'Arial', sans-serif;
    color:#D7D7D7;
    font-size: 16px;
    width: 62%;
}

</style>